/* eslint-disable new-cap */
/* eslint-disable no-underscore-dangle */
import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';

import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/plugins/monthSelect/style.css';

const options = {
  altInput: true,
  altFormat: 'd/m/Y',
  dateFormat: 'Y-m-d',
};

const timeOptions = {
  enableTime: true,
  noCalendar: true,
  dateFormat: 'H:i',
  time_24hr: true,
  // defaultDate: '08:00',
};

const monthYearOptions = {
  // static: true,
  altInput: true,
  position: 'right',
  plugins: [
    new monthSelectPlugin({
      shorthand: false,
      dateFormat: 'Y-m-d',
      altFormat: 'M Y',
    }),
  ],
};

const daterangeOptions = {
  mode: 'range',
  dateFormat: 'Y-m-d',
};

const createFlatpickr = (element, opt) => {
  flatpickr(element, opt);
};

const destroyEl = (el) => {
  el._flatpickr.destroy();
};

export default class extends Controller {
  static targets = [
    'input',
    'inputToday',
    'dateFrom',
    'dateTo',
    'timeInput',
    'monthYear',
    'inputRange',
    'inputRangeTwoField',
  ];

  inputTargetConnected(inputTarget) {
    createFlatpickr(inputTarget, options);
  }

  inputTargetDisconnected(el) {
    destroyEl(el);
  }

  dateFromTargetConnected() {
    createFlatpickr(this.dateFromTarget, {
      ...options,
      maxDate: this.dateToTarget.value || this.dateToTarget.dataset.defaultDate,
      defaultDate: this.dateFromTarget.dataset.defaultDate
    });
  }

  dateFromTargetDisconnected(el) {
    destroyEl(el);
  }

  dateToTargetConnected() {
    createFlatpickr(this.dateToTarget, {
      ...options,
      minDate: this.dateFromTarget.value || this.dateFromTarget.dataset.defaultDate,
      defaultDate: this.dateToTarget.dataset.defaultDate
    });
  }

  dateToTargetDisconnected(el) {
    destroyEl(el);
  }

  inputTodayTargetConnected(inputTodayTarget) {
    const newOptions = {
      ...options,
      ...{ minDate: 'today' },
    };

    createFlatpickr(inputTodayTarget, newOptions);
  }

  inputTodayTargetDisconnected(el) {
    destroyEl(el);
  }

  timeInputTargetConnected(timeInputTarget) {
    flatpickr(timeInputTarget, timeOptions);
  }

  timeInputTargetDisconnected(el) {
    destroyEl(el);
  }

  monthYearTargetConnected(monthYearTarget) {
    flatpickr(monthYearTarget, monthYearOptions);
  }

  monthYearTargetDisconnected(el) {
    destroyEl(el);
  }

  from() {
    createFlatpickr(this.dateToTarget, {
      ...options,
      minDate: this.dateFromTarget.value,
      defaultDate: this.dateToTarget.value
    });
  }

  to() {
    createFlatpickr(this.dateFromTarget, {
      ...options,
      maxDate: this.dateToTarget.value,
      defaultDate: this.dateFromTarget.value
    });
  }

  inputRangeTargetConnected(inputRangeTarget) {
    createFlatpickr(inputRangeTarget, daterangeOptions);
  }

  inputRangeTargetDisconnected(el) {
    destroyEl(el);
  }

  inputRangeTwoFieldTargetConnected(inputRangeTwoFieldTarget) {
    flatpickr(inputRangeTwoFieldTarget, {
      options,
      plugins: [new rangePlugin({ input: '#secondRangeInput' })],
    });
  }

  inputRangeTwoFieldTargetDisconnected(el) {
    destroyEl(el);
  }
}
