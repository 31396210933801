const changeLoadingState = (event) => {
  const el = event.currentTarget || event.target;
  if (!el.classList.contains('btn-loading')) {
    el.classList.add('btn-loading');
  }
}

const revertLoadingState = (event) => {
  const el = event.currentTarget || event.target;
  if (el.classList.contains('btn-loading')) {
    el.classList.remove('btn-loading');
  }
}

export { changeLoadingState, revertLoadingState };
