import Choices from 'choices.js';
import { Controller } from '@hotwired/stimulus';

import 'choices.js/public/assets/styles/choices.min.css';

export default class extends Controller {
  static targets = ['select'];

  constructor(context) {
    super(context);
    this.instances = {};
  }

  selectTargetConnected(currentSelectTarget) {
    const instanceId = this.generateInstanceId(currentSelectTarget);
    this.instances[instanceId] = new Choices(currentSelectTarget, {
      removeItemButton: true,
      itemSelectText: '',
      removeItems: false,
      position: 'auto',
    });
  }

  selectTargetDisconnected(currentSelectTarget) {
    const instanceId = this.getInstanceId(currentSelectTarget);
    this.instances[instanceId]?.destroy();
    delete this.instances[instanceId];
  }

  generateInstanceId(element) {
    const uniqeId = `${Date.now().toString(36)}-${Math.random()
      .toString(36)
      .slice(2)}`;
    // eslint-disable-next-line no-param-reassign
    element.dataset.choicesId = uniqeId;
    return uniqeId;
  }

  getInstanceId(element) {
    return element.dataset.choicesId;
  }
}
