/* eslint-disable import/no-unresolved */
import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
// import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ['source', 'btnCopy'];

  // static debounces = ['callSearch'];

  connect() {
    const url = document.URL;
    if (url.includes('admin/schedules') || url.includes('admin/calendar')) {
      document
        .getElementById('navbar-menu')
        .querySelector('.dropdown-menu')
        .classList.add('show');
    }
    // useDebounce(this, { wait: 500 });
  }

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.getAttribute('value'));

    this.btnCopyTarget.innerHTML = 'Copied';

    setTimeout(() => {
      this.btnCopyTarget.innerHTML = 'Copy';
    }, 1000);
  }

  showPassword(event) {
    event.currentTarget.classList.add('d-none');
    event.target.parentElement.children[1].classList.remove('d-none');
    event.currentTarget
      .closest('.input-group')
      .querySelector('.form-control')
      .setAttribute('type', 'text');
  }

  hidePassword(event) {
    event.currentTarget.classList.add('d-none');
    event.target.parentElement.children[0].classList.remove('d-none');
    event.currentTarget
      .closest('.input-group')
      .querySelector('.form-control')
      .setAttribute('type', 'password');
  }

  rotateIcon(target) {
    const iconEl = target.currentTarget.querySelector('img');
    const { classList } = iconEl;
    const hideEl = iconEl.closest('div')?.querySelector('.text-blue');

    if (classList.value.includes('rotated')) {
      classList.remove('rotated');
      if (hideEl !== null) {
        hideEl.innerText = 'Hide';
      }
    } else {
      classList.add('rotated');
      if (hideEl !== null) {
        hideEl.innerText = 'Show';
      }
    }
  }

  callSearch(inputTarget) {
    const { target } = inputTarget;
    const result = document.getElementById(`${target.dataset.elementId}`);

    Rails.ajax({
      url: `${target.dataset.urlValue}?search=${target.value}`,
      type: 'get',
      dataType: 'json',
      success: (res) => {
        result.innerHTML = res.html;
      },
    });
  }
}
