import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

const BASE_URL = '/notifications';
const DATA_TYPE = 'JSON';

const updateNotificationStatus = (event, action) => {
  const notiEl = event.currentTarget;
  Rails.ajax({
    url: `${BASE_URL}/${notiEl.dataset.id}/${action}`,
    type: 'PUT',
    dataType: DATA_TYPE,
    success: (res) => {
      const el = notiEl.parentElement;
      el.innerHTML = res.html_noti;

      if (action === 'read') {
        el.classList.remove('unread');
        el.classList.add('readed');
      } else {
        el.classList.remove('readed');
        el.classList.add('unread');
      }
      document.getElementById('flash').innerHTML = res.html_flash;
    },
  });
};

export default class extends Controller {
  static targets = ['list', 'readAll', 'today', 'previous'];

  connect() {
    console.log('connect notification!!');
  }

  getNotifications() {
    Rails.ajax({
      url: `${BASE_URL}?today=true`,
      type: 'GET',
      dataType: DATA_TYPE,
      success: (res) => {
        this.listTarget.innerHTML = res.html;
      },
    });
  }

  read(event) {
    updateNotificationStatus(event, 'read');
  }

  unread(event) {
    updateNotificationStatus(event, 'unread');
  }

  readAll(event) {
    const bodyOffCanvas = document.getElementById('offcanvasNotificationBody');
    const tabActive = bodyOffCanvas.querySelector('.active');

    const el = event.target;
    if (el.classList.contains('text-green')) {
      Rails.ajax({
        url: `${BASE_URL}/read_all?today=${tabActive.dataset.today}`,
        type: 'PUT',
        dataType: DATA_TYPE,
        success: (res) => {
          this.listTarget.innerHTML = res.html;
        },
      });
    } else {
      el.classList.add('text-muted');
    }
  }

  today(event) {
    this.listNotification(event);
  }

  previous(event) {
    this.listNotification(event);
  }

  readMore(event) {
    const el = event.currentTarget;
    const { nextPage } = el.dataset;
    const offcanvasNotificationBodyEl = document.getElementById(
      'offcanvasNotificationBody',
    );
    const status =
      offcanvasNotificationBodyEl?.querySelector('.active').dataset.today;

    Rails.ajax({
      url: `${BASE_URL}?today=${status}&page=${nextPage}`,
      type: 'GET',
      dataType: DATA_TYPE,
      success: (res) => {
        offcanvasNotificationBodyEl.querySelector('#readMoreBtn').remove();
        offcanvasNotificationBodyEl.querySelector(
          '#data-list-notifications',
        ).innerHTML += res.html;
      },
    });
  }

  listNotification = (event) => {
    const status = event.currentTarget.dataset.today;
    Rails.ajax({
      url: `${BASE_URL}?today=${status}`,
      type: 'GET',
      dataType: DATA_TYPE,
      success: (res) => {
        this.listTarget.innerHTML = res.html;
        if (status === 'true') {
          this.todayTarget.classList.add('active');
          this.previousTarget.classList.remove('active');
        } else {
          this.previousTarget.classList.add('active');
          this.todayTarget.classList.remove('active');
        }
      },
    });
  };
}
